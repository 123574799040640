$animation-distance: 20%;

.icon {
  clip-path: polygon(10% 0%, 90% 0%, 100% 10%, 100% 90%, 90% 100%, 10% 100%, 0% 90%, 0% 10%);

  path {
    fill: currentColor;
    transition: transform 0.3s;
  }
}

.right {
  transform: translateX($animation-distance);

  path {
    transform: translateX(-1 * $animation-distance);

    a:hover &,
    button:not(:disabled):hover & {
      transform: translateX(0);
    }
  }
}

.left {
  transform: translateX(-1 * $animation-distance);

  path {
    transform: translateX($animation-distance);

    a:hover &,
    button:not(:disabled):hover & {
      transform: translateX(0);
    }
  }
}

.diagonal {
  transform: translate($animation-distance, -1 * $animation-distance);

  path {
    transform: translate(-1 * $animation-distance, $animation-distance);

    a:hover &,
    button:not(:disabled):hover & {
      transform: translate(0, 0);
    }
  }
}
