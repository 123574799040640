@import '/styles/base.scss';

.link {
  color: currentColor;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.65;
  }
}

.noWrap {
  display: inline;
  white-space: nowrap;
}

.notIsFullBleed {
  @include focus;
}

.icon {
  width: auto;
  height: 1.4em;
  vertical-align: middle;

  &.right,
  // regardless of their side, arrows are always small
  &.arrow {
    width: auto;
    height: 0.85em;
  }
}
