@import '/styles/base.scss';
@import '../navigation.constants.module.scss';

.navigationPlaceholder {
  display: none;

  @include lg {
    display: block;
    width: var(--nav-width-base);
    height: auto;
    flex-shrink: 0;
  }
}

.navigationPlaceholderHomepage {
  background-color: var(--theme-primary);
}
