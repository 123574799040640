@import '/styles/base.scss';

.entry {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 0.6rem;
}

.border {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $color-black;
  opacity: 0.3;
  transition: opacity 0.3s ease;

  :global(.themed) & {
    border-bottom: 1px solid var(--theme-copy);
  }
}

.input {
  width: 100%;
  flex: 1;
  padding: 0 0.8rem 0 0;
  border: none;
  background: none;
  color: $color-black;
  line-height: 1;
  outline: none;

  &.overflow {
    direction: rtl;
  }

  &:focus {
    direction: ltr;
  }

  :global(.themed) & {
    color: var(--theme-copy);
  }

  &::placeholder {
    color: rgba($color-black, 0.65);

    :global(.themed) & {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }

  @include md {
    padding-right: 1.7rem;
  }

  &:focus-visible {
    + .focusHandler {
      display: none;
    }

    & ~ * {
      opacity: 1;
    }
  }
}

.action {
  @include focus;
  flex: 0;
  padding: 0;
  border: none;
  margin: 0;
  color: $color-black;
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    color 0.3s ease;

  &:hover {
    color: rgba($color-black, 0.65);
  }

  :global(.themed) & {
    color: var(--theme-copy);

    &:hover {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }

  &.isDisabled {
    cursor: default;
    pointer-events: none;
  }
}

.icon {
  width: 3.2rem;

  @include md {
    width: 4rem;
  }

  @include lg {
    width: 4.8rem;
  }
}

.focusHandler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: text;
}
