@import '/styles/base.scss';
@import './navigation.constants.module.scss';

.navigationWrapper {
  position: sticky;
  z-index: $z-200;
  top: -0.1rem;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: $primary-sticky-mobile-height;
  pointer-events: none;

  @include md {
    height: $primary-sticky-tablet-height;
  }

  @include lg {
    position: absolute;
    height: auto;
  }
}
