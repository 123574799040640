@import '/styles/base.scss';
@import '~/v1/components/navigation/navigation.constants.module.scss';

.root {
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.page {
  position: relative;
}

.main {
  display: flex;
  // vw units don't take scrollbar width into account, causing horizontal overflow
  width: 100%;
  max-width: 100vw;
  min-height: calc(100 * var(--vh));
  min-height: 100svh;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: calc(#{$primary-mobile-height} - #{$primary-sticky-mobile-height});

  @include md {
    padding-top: calc(#{$primary-tablet-height} - #{$primary-sticky-tablet-height});
  }

  @include lg {
    min-height: calc(100 * var(--vh));
    flex-direction: row;
    padding-top: 0;
  }

  &Homepage {
    padding-top: 0;
  }
}

.nav {
  width: 100vw;
  height: 10rem;
  background-color: var(--theme-primary);

  @include lg {
    width: 20%;
    height: auto;
    min-height: calc(100 * var(--vh));
  }
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  @include lg {
    width: 80%;
  }
}
