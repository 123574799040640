@use '~/ui/styles/breakpoints.module';

.column {
  // This will always be overridden by inline styles
  --span-sm: var(--columns-sm);
  --span-md: var(--columns-md);
  --span-lg: var(--columns-lg);

  flex-basis: calc((var(--span-sm) / var(--columns-sm)) * 100%);

  box-sizing: border-box;
  width: calc((var(--span-sm) / var(--columns-sm)) * 100%);
  margin-right: calc((var(--or-sm) / var(--columns-sm)) * 100%);
  margin-left: calc((var(--ol-sm) / var(--columns-sm)) * 100%);

  @media (min-width: breakpoints.$md) {
    flex-basis: calc((var(--span-md) / var(--columns-md)) * 100%);
    width: calc((var(--span-md) / var(--columns-md)) * 100%);
    margin-right: calc((var(--or-md) / var(--columns-md)) * 100%);
    margin-left: calc((var(--ol-md) / var(--columns-md)) * 100%);
  }

  @media (min-width: breakpoints.$lg) {
    flex-basis: calc((var(--span-lg) / var(--columns-lg)) * 100%);
    width: calc((var(--span-lg) / var(--columns-lg)) * 100%);
    margin-right: calc((var(--or-lg) / var(--columns-lg)) * 100%);
    margin-left: calc((var(--ol-lg) / var(--columns-lg)) * 100%);
  }
}

.allowOverflow {
  min-width: calc((var(--span-sm) / var(--columns-sm)) * 100%);

  @media (min-width: breakpoints.$md) {
    min-width: calc((var(--span-md) / var(--columns-md)) * 100%);
  }

  @media (min-width: breakpoints.$lg) {
    min-width: calc((var(--span-lg) / var(--columns-lg)) * 100%);
  }
}
