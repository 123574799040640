$margin-sm: 2rem;
$margin-md: 4.8rem;
$margin-lg: auto;

$columns-sm: 5;
$columns-md: 7;
$columns-lg: 9;

$max-grid-width: 153rem;

// stylelint-disable
:export {
  columnsSm: $columns-sm;
  columnsMd: $columns-md;
  columnsLg: $columns-lg;
  // TODO: I would prefer if we used the variables here instead of having to repeat the values in px
  marginSm: 20px;
  marginMd: 48px;
  marginLg: 0;
  maxGridWidth: 1530px;
}
// stylelint-enable
