@import '/styles/base.scss';

.outerWrapper {
  position: relative;
  padding-bottom: 0.8rem;
}

.outerWrapperDisabled {
  opacity: 0.3;
}

.innerWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.textInput {
  overflow: hidden;
  width: 100%;
  padding: 0;
  padding-right: 1rem;
  border: none;
  background-color: transparent;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  .outerWrapperThemed & {
    color: var(--theme-copy);
  }
}

.textInput,
.label {
  @include caption;

  @include lg {
    @include bodyLarge;
  }
}

.actionButton {
  @include link;

  @include lg {
    @include linkLarge;
  }
}

.inputBorder {
  position: absolute;
  bottom: -0.8rem;
  width: 100%;
  border-bottom: 1px solid $color-black;
  transition: opacity 0.3s ease;

  .outerWrapperThemed & {
    border-bottom-color: var(--theme-copy);
  }

  .textInput:hover ~ & {
    opacity: 0.65;
  }
}

.label {
  display: inline-block;
  opacity: 0.6;
  pointer-events: none;
  // This is a stopgap solution until Safari and Firefox supports line height units
  // https://caniuse.com/mdn-css_types_length_lh
  transform: translateY(1.25em);
  transform: translateY(1lh);
  transform-origin: bottom left;
  transition: all 0.2s;
}

.labelActive {
  opacity: 1;
  transform: translateY(-1rem) scale(0.75);
}

.loadingIcon {
  position: absolute;
  right: 0;
  width: 3.2rem;
}

.actionButton {
  display: inline-flex;
  align-items: flex-end;
  padding: 0;
  color: $color-black;
  cursor: pointer;
  transition: opacity 0.3s ease;

  .outerWrapperThemed & {
    color: var(--theme-copy);
  }

  &:hover {
    opacity: 0.65;
  }

  @include focus;
}

.helperText {
  top: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
  opacity: 0.65;
}

.helperTextIcon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
}
