@import '/styles/base.scss';

.root {
  overflow: hidden;
  padding: 6.4rem $margin-sm 2.2rem;
  background-color: var(--theme-primary);
  color: var(--theme-copy);

  @include md {
    padding: 8.8rem $margin-md 3.2rem;
  }

  @include lg {
    padding: 12rem 6.4rem 3.2rem;
  }
}

.staticIdeas {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  word-break: break-word;

  @include lg {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.ideas,
.newsletter {
  margin-bottom: 8.8rem;

  @include md {
    margin-bottom: 12rem;
  }
}

.title {
  margin-bottom: 4rem;

  @include lg {
    margin-bottom: 6rem;
  }
}

.bottomNav {
  list-style-type: none;
}

.newsletterWrapper {
  display: grid;
  width: 100%;

  .newsletter {
    padding: 0;
    background: var(--theme-primary);

    @include lg {
      width: 40%;
      justify-self: flex-end;
    }
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 6.4rem;

  @include md {
    margin-top: 3.2rem;
  }

  @include lg {
    flex-direction: row;
    align-items: center;
    margin-top: 6.4rem;
  }
}

.iconsWrapper {
  display: flex;
  flex-direction: column;

  @include md-only {
    width: 100%;
  }

  @include md {
    flex-direction: row;
    align-items: center;
  }

  @include sm-only {
    margin-top: 3.2rem;
  }

  @include lg {
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: space-between;
  }
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.footerLinks > li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  a {
    margin-right: 2.4rem;

    @include md {
      white-space: nowrap;
    }
  }

  @include sm-only {
    flex-basis: 50%;
    flex-direction: column;
    align-items: start;
  }
}

.line {
  display: none;

  @include lg {
    display: block;
    padding: 1rem 0;
    margin-right: 2.4rem;

    svg {
      height: 2rem;
    }
  }
}

.icons {
  display: flex;

  @include sm-only {
    margin-top: 2.2rem;
  }

  @include md {
    margin-left: 2.4rem;
  }
}

.logo {
  @include lg {
    width: calc(100% - 20rem);
  }
}
