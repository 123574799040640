@import '/styles/base.scss';

.logo {
  @include lg {
    width: calc(100% - 20rem);
  }

  svg > g > g:first-child > g > path {
    fill: var(--theme-copy);
  }

  svg > g > g:nth-child(2) > g > path {
    fill: rgba(var(--theme-copy-rgb), 0.8);
  }

  svg > g > g:nth-child(3) > g > path {
    fill: var(--theme-copy);
  }
}
