@import '/styles/base.scss';
@import '../navigation.constants.module.scss';

.secondaryNavigationWrapper {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: calc(100 * var(--vh));
  flex: 1;
  pointer-events: none;

  @include lg {
    position: sticky;
    width: auto;
  }
}

.secondaryNavigationWrapperActive {
  z-index: $z-200;
  pointer-events: all;

  @include lg {
    z-index: 0;
  }
}

.secondaryNavigation {
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  background: var(--theme-primary);

  @include lg {
    // it takes 8 out of 9 grid columns 8/9 = 88.9%;
    width: 88.9%;
    max-width: 136rem;
    flex: initial;
    transform: translateX(-100%);
  }
}

.navigationInnerWrapper {
  position: relative;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  @include lg {
    display: block;
    height: 100%;
  }
}

.secondaryNavigationActive {
  z-index: 2;
}

.secondaryNavigationTop {
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: (var(--theme-primary));
  color: var(--theme-copy);
  @include md {
    padding: 2rem 3.6rem;
  }

  @include lg {
    position: relative;
    height: auto;
    padding: 0;
    border-bottom: none;
  }
}

.topButtonWrapper {
  @include lg {
    position: absolute;
    z-index: 1;
    top: 3.2rem;
    right: 3.2rem;
  }
}

.topButton {
  display: flex;
  padding: 1.2rem;
  border-radius: 50%;
  background-color: var(--theme-primary);
  color: var(--theme-copy);
  @include focus(0);
  transition: color 0.3s ease;

  @include lg {
    padding: 1rem;
  }
  &:hover {
    color: rgba(var(--theme-copy-rgb), 0.65);
    cursor: pointer;
  }
}

.arrowButton,
.topLabel {
  @include lg {
    display: none;
  }
}

.arrowButton {
  > span {
    width: 2rem;
    height: 2rem;
  }

  .arrowIcon {
    width: 2rem;
    height: 2rem;
  }
}

.closeIcon {
  width: 2rem;

  @include lg {
    width: 2.8rem;
  }
}

.content {
  padding: 4rem 2rem 6.4rem;
  @include md {
    padding: 4.8rem;
  }
  @include lg {
    display: flex;
    min-height: 100%;
    flex: 1;
    justify-content: center;
    padding: 0;
  }
}

.contentInner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  @include lg {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.searchWrapper {
  width: 100%;
  @include md {
    padding-top: 11rem;
  }
  @include lg {
    width: 75%;
    padding-top: 20rem;
  }
}

.itemsWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0;

  @include lg {
    // it takes 3 out of 8 grid columns (.secondaryNavigation takes up 8)
    // 3/8 = 37.5%
    width: 37.5%;
    padding-top: calc(var(--nav-wordmark-height) + 2 * var(--nav-wordmark-spacing));
    padding-left: 0.6rem;
  }
}

.contentRight {
  position: relative;
  @include md {
    width: 86%;
  }
  @include lg {
    // it takes 5 out of 8 grid columns (.secondaryNavigation takes up 8)
    // 5/8 = 62.5%
    width: 62.5%;
  }
}

.secondaryNavigationItem {
  display: flex;
  margin-bottom: 1.6rem;

  @include lg {
    margin-bottom: 0;
  }
}

.secondaryNavigationItemLink {
  @include focus;
  @include modulesTitle;
  @include lg {
    @include bodyLarge;
  }
  display: flex;
  align-items: center;
  padding: 0;

  @include lg {
    margin-bottom: 0.8rem;
  }
}

.secondaryNavigationItemImage {
  display: none;
  width: 5rem;
  height: 6.4rem;
  margin-right: 1.2rem;

  @include lg {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    margin-right: 0;

    img {
      object-fit: cover;
    }
  }
}

.linkImage {
  display: block;

  @include lg {
    display: none;
  }
}

.statement {
  max-width: 100%;
  align-self: flex-end;
  margin-top: 6.4rem;
  color: var(--theme-copy);
  opacity: 1;
  transition: opacity 0.5s ease;
  transition-delay: 0.2s;

  @include lg {
    position: fixed;
    right: 4rem;
    bottom: 4rem;
    width: 48rem;
    margin-top: 0;
  }
}

.statementFaded {
  transition: none;
  @include lg {
    opacity: 0;
    transition: opacity 0.2s ease;
    transition-delay: 0s;
  }
}

.noTransitions * {
  transition: none;
}

.backdropButton {
  position: absolute;
  display: none;
  width: 100%;
  background-color: $color-black;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  .secondaryNavigationWrapperActive & {
    opacity: 0.7;
    pointer-events: all;
  }

  @include lg {
    display: block;
  }
}
