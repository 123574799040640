@use '/styles/base.scss' as *;

.ideaLink {
  @include focus;
  @include modulesTitle;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 1.6rem;

  // the diagonal line is only rendered on devices with cursors
  &:has(> svg) {
    margin-right: 1.2rem;
  }

  & > svg {
    display: block;
    height: 5rem;
    margin: 0 0 0 0.8rem;
  }

  @include lg {
    @include sectionTitle;
    white-space: nowrap;
  }
}

.ideaImage {
  width: 5rem;
  height: 6.4rem;
  margin-right: 1.2rem;
}
