@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/grid.module';

.grid {
  // Set the number of columns to the containing grid size or the default
  --columns-sm: var(--span-sm, #{grid.$columns-sm});
  --columns-md: var(--span-md, #{grid.$columns-md});
  --columns-lg: var(--span-lg, #{grid.$columns-lg});

  // Reset the offset left and offset right values
  // TODO: This is a temporary workaround until the @property at-rule has better support: https://caniuse.com/mdn-css_at-rules_property
  --ol-sm: 0;
  --or-sm: 0;
  --ol-md: 0;
  --or-md: 0;
  --ol-lg: 0;
  --or-lg: 0;

  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-wrap: wrap;

  width: calc(100% - #{2 * grid.$margin-sm});
  margin-inline: grid.$margin-sm;

  @media (min-width: breakpoints.$md) {
    width: calc(100% - #{2 * grid.$margin-md});
    margin-inline: grid.$margin-md;
  }

  @media (min-width: breakpoints.$lg) {
    width: 100%;
    max-width: grid.$max-grid-width;
    margin-inline: auto;
  }
}

.grid .grid,
.fullWidth {
  width: 100%;
  margin-inline: 0;

  @media (min-width: breakpoints.$lg) {
    margin-inline: auto;
  }
}

@property --or-sm {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}

@property --ol-sm {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}

@property --or-md {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}

@property --ol-md {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}

@property --or-lg {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}

@property --ol-lg {
  inherits: false;
  initial-value: 0;
  syntax: '<number>';
}
