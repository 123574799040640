@import '/styles/base.scss';

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  animation: loading 1.7s ease infinite;
  background-color: rgba($color-black, 0.08);
}
