@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/grid.module';

.gridWrapper {
  pointer-events: none;

  position: fixed;
  z-index: -1;
  inset: 0;

  display: flex;
}

.column {
  border-right: 0.1rem solid rgba(0, 0, 0, 0.08);

  &:first-child {
    border-left: 0.1rem solid rgba(0, 0, 0, 0.08);
  }

  // Rather than use useBreakpoint to determine how many columns to render, we can just hide additional columns. This
  //  just hides all columns over 5 (the amount we need for sm) on md and above and all columns over 7 (md) on lg and
  //  above.
  @for $i from grid.$columns-sm through grid.$columns-lg {
    @if ($i > grid.$columns-sm) {
      @media (max-width: breakpoints.$md - 1) {
        &:nth-child(#{$i}) {
          display: none;
        }
      }
    }

    @if ($i > grid.$columns-md) {
      @media (max-width: breakpoints.$lg - 1) {
        &:nth-child(#{$i}) {
          display: none;
        }
      }
    }
  }
}
