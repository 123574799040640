@import '/styles/base.scss';

$light-mode-border-default: rgba(0, 0, 0, 0.15);
$light-mode-border-hover: rgba(0, 0, 0, 0.65);

$dark-mode-border-default: rgba(255, 255, 255, 0.25);
$dark-mode-border-hover: rgba(255, 255, 255, 0.65);

.button {
  display: inline-flex;
  height: 5.4rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 3.2rem;
  border: none;
  border-radius: 50rem;
  transition:
    background 0.3s,
    border 0.3s;

  &:hover:not([disabled]) {
    cursor: pointer;
  }
}

.isNotFullBleed {
  @include focus;
}

.button-tiny {
  height: 3.8rem;
  padding: 0 2.4rem;
}

.button-icon {
  width: 4.4rem;
  height: 4.4rem;
  padding: 0;
}

.button-medium {
  height: 5.6rem;
  padding: 0 2rem;
}

.button-secondary {
  @include focus(0.4rem);
  border-width: 0.2rem;
  border-style: solid;
}

.button-primary-light {
  background: $color-black;
  color: $color-white;

  &:hover {
    background: lighten($color-black, 25%);
  }

  &:disabled {
    background: lighten($color-black, 25%);
    color: rgba(255, 255, 255, 0.65);
  }
}

.button-secondary-light {
  border-color: $light-mode-border-default;
  background-color: $color-white;
  color: $color-black;

  &:hover {
    border-color: $light-mode-border-hover;
  }

  &:disabled {
    border-color: $light-mode-border-default;
    color: rgba(0, 0, 0, 0.65);
  }
}

.button-primary-dark {
  background: $color-white;
  color: $color-black;

  &:hover {
    background: darken($color-white, 10%);
  }

  &:disabled {
    background: darken($color-white, 10%);
    color: rgba(0, 0, 0, 0.65);
  }
}

.button-secondary-dark {
  border-color: $dark-mode-border-default;
  background-color: $color-black;
  color: $color-white;

  &:hover {
    border-color: $dark-mode-border-hover;
  }

  &:disabled {
    border-color: $dark-mode-border-default;
    color: rgba(255, 255, 255, 0.65);
  }
}

.button-theme {
  background-color: var(--theme-primary);

  &:hover {
    background-color: rgba(var(--theme-primary-rgb), 0.65);
  }

  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}

.button-theme-light {
  color: $color-black;
}

.button-theme-dark {
  color: $color-white;
}

.buttonTransparent {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}
