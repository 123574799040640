@use '/styles/base.scss' as *;

@value icon from '~/v1/components/expandingArrow/expandingArrow.module.scss';

.root {
  position: relative;

  img,
  svg {
    transition: transform 0.3s;
  }

  /*  */
  *:has(> img),
  *:has(> svg),
  .trigger {
    /**
     * Animated elements break out of overflow:hidden unless they have their own stacking context.
     * https://stackoverflow.com/a/58283449
     */
    isolation: isolate;
  }

  &:has(.trigger:hover),
  &:has(.trigger:focus-visible) {
    & img,
    // dont scale expanding arrows (which have their own animation)
    & svg:not(.icon) {
      transform: scale(1.03);
    }
  }

  &:has(.trigger:focus-visible) {
    &::before,
    &::after {
      position: absolute;
      z-index: 2;
      display: block;
      content: '';
      inset: 0;
      outline-style: solid;
      outline-width: 0.2rem;
    }

    &::before {
      outline-color: $color-white;
      outline-offset: -0.4rem;
    }

    &::after {
      outline-color: $color-black;
      outline-offset: -0.2rem;
    }
  }
}

.trigger {
  outline: none;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    z-index: 5;
  }
}
