@import '/styles/base.scss';

.suggestion {
  margin-top: 3.2rem;
  color: $color-black;

  @include lg {
    margin-top: 4.8rem;
  }

  :global(.themed) & {
    color: var(--theme-copy);
  }
}

.title {
  margin-bottom: 1.6rem;
  color: rgba($color-black, 0.65);

  :global(.themed) & {
    color: rgba(var(--theme-copy-rgb), 0.65);
  }
}

.list {
  list-style-type: none;
}

.listItem {
  & + & {
    margin-top: 0.8rem;
  }
}

.link {
  color: $color-black;
  transition: color 0.3s ease;

  &:hover {
    color: rgba($color-black, 0.65);
  }

  :global(.themed) & {
    color: var(--theme-copy);

    &:hover {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }
}

.skeleton {
  max-width: 40%;
  margin-top: 3.2rem;

  @include lg {
    margin-top: 4.8rem;
  }

  > div {
    height: 2.2rem;
    animation: loading 1.7s ease infinite;
    background-color: rgba($color-black, 0.08);

    :global(.themed) & {
      background-color: rgba(var(--theme-copy-rgb), 0.08);
    }

    & + div {
      margin-top: 1.2rem;
    }

    &:nth-child(1) {
      width: 60%;
      height: 1.4rem;
    }

    &:nth-child(2) {
      width: 85%;
      margin-top: 1.9rem;
    }

    &:nth-child(3) {
      width: 100%;
    }

    &:nth-child(4) {
      width: 80%;
    }
  }
}
