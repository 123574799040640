@import '/styles/base.scss';

.noResults {
  @include focus;
  margin-top: 3.2rem;
  color: rgba($color-black, 0.6);

  @include lg {
    margin-top: 4.8rem;
  }

  :global(.themed) & {
    color: rgba(var(--theme-copy-rgb), 0.6);
  }
}
