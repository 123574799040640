@use '/styles/base.scss' as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: auto calc(-1 * $margin-md);
}

.marquee {
  z-index: 1;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  animation-iteration-count: infinite;
  animation-name: scroll;
  animation-timing-function: linear;

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.marqueeInner {
  display: flex;
  min-width: auto;
  flex: 0 0 auto;
  flex-direction: row;
}
